import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  QuickFacts,
  BannerLinks,
  BannerSmall,
  CallUs,
  ProjectPagination,
  Image,
  VideoAndText
} from '../../components'

import boxes from '../../components/BannerLinks/DefaultBox'
import contacts from '../../components/CallUs/DefaultContacts'

import { generateBreadcrumbsLinks } from '../../utils/index'

import BannerImage from '../../assets/header.arbeiterkammer.png'
import image4 from '../../assets/bannerlinks/bannerbox.ikb.jpg'
import image5 from '../../assets/stadtamthall.uebersicht.jpg'
import Michael from '../../assets/michael.obermayr.jpg'

export const frontmatter = {
  title: 'Arbeiterkammer.js',
  slug: '/Projekte/Arbeiterkammer.js'
}

const Arbeiterkammer = () => {
  const links = generateBreadcrumbsLinks('/projekte/arbeiterkammer', 'Arbeiterkammer Tirol')
  return (
    <Layout margin={false} color="white">
      <Helmet
        title="Arbeiterkammer Tirol"
        meta={[
          {
            name: 'description',
            content:
              'Die Arbeiterkammer Tirol mit ihrer tollen neuen Webseite!'
          },
          {
            name: 'keywords',
            content:
              'Arbeiterkammer Tirol holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, Arbeiterkammer Tirol, Arbeiterkammer, Tirol, AK Tirol, AK'
          },
          {
            property: 'og:description',
            content:
              'Die Arbeiterkammer Tirol mit ihrer tollen neuen Webseite!'
          },
          {
            property: 'og:image',
            content:
              'https://www.holzweg.com/static/header.arbeiterkammer-391f4c19b2c3f8e061a56d6000d7389d.png'
          }
        ]}
      />
      <BannerSmall image={BannerImage} title="Arbeiterkammer Tirol" />
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={
          <div>
            Online-mit-Köpfchen:
          </div>
        }
        text={
          <span>
            <i>
              <p>Die große Präsenz der digitalen Welt im Leben junger Menschen gab den Anlass einen neuen Workshop der AK werkstatt ins Leben zu rufen. Die Idee war klar: Es soll ein Workshop entstehen, der in einer Web-App zur Gänze digital stattfindet. Die Inhalte sollen Schüler auf ihren Umgang im Netz sensibilisieren. Der Name war daher schnell geboren: „Online mit Köpfchen“.</p>
              <p>Themen wie Urheberrecht, Datenschutz oder auch Medienkompetenz werden während des Workshops erarbeitet. Die große Herausforderung dabei: Noch nie dagewesene Instrumente zu erschaffen, um diese komplexen Inhalte Workshop-gerecht aufbereiten zu können. </p>
              <p>Als Partner für dieses große Projekt entschieden wir uns für holzweg. Die Erarbeitung der App konnte nur gemeinsam erfolgen, da jede inhaltliche bzw. grafische Idee auf ihre digitale Umsetzung überprüft werden musste. Das Arbeiten im Team ermöglichte uns das Schaffen von kreativen Tools. Nach der ersten Arbeitsphase fand eine Einschulung in Sachen „Befüllung der Web-App“ statt. Während das Team rund um die AK werkstatt die Seite mit Inhalten fütterte, konnten sie über ein eingerichtetes Ticketsystem ständig in Kontakt mit holzweg bleiben. Probleme bzw. Fragen konnten so rasch und übersichtlich gelöst werden.</p>
              <p>Ein Workshop mit derartigen Inhalten und Aufbereitung ist auf dem Markt einzigartig. Speziell für diese Art von Projekten braucht es zuverlässige Partner wie holzweg. Nicht nur wir als Team erfreuen uns am Ergebnis. Dieses findet vor allem Anklang bei den Schülerinnen und Schülern, die so spielerisch ihren Umgang mit dem Internet hinterfragen können. Wir sind auf die gemeinsame Errungenschaft mit holzweg sehr stolz und freuen uns auf die weitere Zusammenarbeit.</p>
              <p>Mehr Informationen zum Workshop und den anderen für Schulen kostenlosen Angeboten der AK Tirol finden Sie hier: <a href="https://tirol.arbeiterkammer.at/service/akwerkstatt/Workshops/Workshop_Online_mit_Koepfchen.html">Workshop_Online_mit_Koepfchen</a></p>


            </i>
          </span>
        }
      />
      <Image
        image={Michael}
        title=""
      />
      <TextBox title="" text={
        <span><p><b>Dr. Michael Obermeier, BEd.</b><br />AK-Tirol – Projektleitung "Online mit Köpfchen"</p>
        </span>
      }
      />

      <VideoAndText 
      YTvideo={"NhhHsmJs9zI?si=pdlfl1Ds9x1swX-R"}
      />

      <CallUs contacts={contacts} title="Gerne für Sie da" />
      <TextBox titleMods={["center"]}
        title="Weitere Projekte"
        text={false}
      />
      <ProjectPagination
        titleLeft="IKB"
        imageLeft={image4}
        leftLink="/projekte/ikb"
        titleRight="Stadtamt Hall Relaunch"
        imageRight={image5}
        rightLink="/projekte/stadtamthall"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Arbeiterkammer
